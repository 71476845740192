<template>
  <b-form @submit.prevent>
    <b-row>
      <!-- tanggal masuk -->
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-group
          class="custom__form--input"
        >
          <label for="v-date-in">Tanggal Masuk <span class="text-danger">*</span></label>
          <b-form-input
            id="v-date-in"
            v-model="formPayload.date"
            type="date"
            class="custom__input"
            style="padding-top: 1rem;"
            placeholder="dd/mm/yyy"
            :disabled="$route.query.q !== 'warehouse' ? !checkPermission('update stok supplier') && isEdit : !checkPermission('update stok gudang') && isEdit"
          />
        </b-form-group>
      </b-col>

      <!-- pilih supplier -->
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-group
          class="custom__form--input"
        >
          <label for="v-supplier">Pilih Pemasok <span class="text-danger">*</span></label>
          <v-select
            v-model="formPayload.supplier_uuid"
            label="title"
            :reduce="option => option.label"
            :options="options"
            placeholder="Pilih Pemasok"
            @search="onSearch"
            :disabled="$route.params.id && (utang && utang.paid > 0) || 
            $route.query.q !== 'warehouse' ? !checkPermission('update stok supplier') && isEdit : !checkPermission('update stok gudang') && isEdit"
          />
        </b-form-group>
      </b-col>

      <!-- nomor po -->
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-form-group class="custom__form--input">
          <label for="v-nomor-po">Nomor PO/SO <span
            v-if="$route.query.warehouse === '0'"
            class="text-danger"
          >*</span></label>
          <b-form-input
            id="v-nomor-po"
            v-model="formPayload.po_so_id"
            class="custom__input"
            type="text"
            placeholder="x-xxxx-xxxx"
            :disabled="$route.query.q !== 'warehouse' ? !checkPermission('update stok supplier') && isEdit : !checkPermission('update stok gudang') && isEdit"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-2"
      >
        <div>
          <TransitionGroup name="fade">
            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in formPayload.delivery_numbers"
              :id="item.id"
              :key="index"
              ref="row"
            >

              <b-col
                cols="12"
                :lg="$route.query.warehouse === '0' ? '9' : '12'"
                :md="$route.query.warehouse === '0' ? '9' : '12'"
                class="mb-50"
              >
                <b-form-group class="custom__form--input">
                  <label :for="`v-delivery-number-${index}`">Nomor Surat Jalan {{ $route.query.warehouse === '1' ? '' : index+1 }} <span class="text-danger">*</span></label>
                  <b-form-input
                    :id="`v-delivery-number-${index}`"
                    v-model="formPayload.delivery_numbers[index].delivery_number_note"
                    class="custom__input"
                    type="text"
                    placeholder="x-xxxx-xxxx"
                    :disabled="$route.query.q !== 'warehouse' ? !checkPermission('update stok supplier') && isEdit : !checkPermission('update stok gudang') && isEdit"
                  />
                </b-form-group>
              </b-col>

              <!-- Remove Button -->
              <b-col
                v-if="$route.query.warehouse === '0'"
                lg="3"
                md="3"
                class="align-self-center"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>Delete</span>
                </b-button>
              </b-col>
            </b-row>
          </TransitionGroup>
          <b-button
            v-if="$route.query.warehouse === '0'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Tambah Surat Jalan</span>
          </b-button>
        </div>
      </b-col>
      <!-- Pilih Gudang Tujuan -->
      <!-- <b-col
        v-if="$route.query.warehouse === '1'"
        cols="12"
        class="mb-2"
      >
        <b-form-group
          class="custom__form--input"
        >
          <label for="v-sender">Pilih Lokasi SKU Tujuan <span class="text-danger">*</span></label>
          <v-select
            v-model="formPayload.warehouse_uuid"
            label="title"
            :reduce="option => option.label"
            :options="optionsWarehouse"
            placeholder="Pilih Lokasi SKU"
          />
        </b-form-group>
      </b-col> -->

      <!-- Jenis Pengiriman -->
      <b-col
        v-if="$route.query.warehouse === '0' && $route.query.q !== 'warehouse'"
        cols="12"
        class="mb-2"
      >
        <b-form-group
          class="custom__form--input"
        >
          <label>Jenis Pengiriman <span class="text-danger">*</span></label>
          <b-form-radio
            v-model="formPayload.shipping"
            name="some-radios"
            value="Franco"
            class="my-1"
          >
            Franco
          </b-form-radio>
          <b-form-radio
            v-model="formPayload.shipping"
            name="some-radios"
            value="Loco"
          >
            Loco
          </b-form-radio>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
/* eslint-disable no-plusplus */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormRadio,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import moment from 'moment'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { checkPermission } from '@/auth/utils'

export default {
  props: {
    utang: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    }
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BFormRadio,
    BButton,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  setup() {
    return {
      checkPermission,
    }
  },
  data() {
    return {
      selectedSupplier: '',
      selectedWarehouse: '',
      options: [],
      suppliers: [],
      optionsShipping: [
        { value: '', text: 'Pilih Pengiriman' },
        { value: 'Franco', text: 'Franco' },
        { value: 'Loco', text: 'Loco' },
      ],
      optionsWarehouse: [],
      formPayload: {
        date: '',
        delivery_numbers: [
          {
            delivery_number_note: '',
          },
        ],
        shipping: '',
        supplier_uuid: '',
        po_so_id: '',
        warehouse_uuid: '',
      },
      nextTodoId: 2,
    }
  },
  watch: {
    selectedSupplier(value) {
      this.formPayload.supplier_uuid = value || ''
    },
    selectedWarehouse(value) {
      this.formPayload.warehouse_uuid = value || ''
    },
    '$store.state.stockWarehouse.formSupplier': {
      handler(value) {
        this.formPayload = value
        if (value.supplier_uuid) {
          this.getSupplierDetail(value.supplier_uuid)
        }
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        const data = value
        const date = moment(data.date).format('YYYY-MM-DD')
        data.date = date
        this.$store.commit('stockWarehouse/setFormSupplier', data)
      },
      deep: true,
    },
    'formPayload.supplier_uuid': function (uuid) {
      localStorage.setItem('customer_supplier_uuid', uuid)
      // if (uuid) {
      //   const supplier = this.suppliers.filter(e => e.uuid === uuid)
      //   console.log(supplier, ' ini supplier')
      //   localStorage.setItem('terms_of_payment_day', supplier.shift()?.terms_of_payment_day || 0)
      // }
    },
  },
  mounted() {
    this.getSupplier()
    this.getWarehouses()
  },
  destroyed() {
    this.$store.commit('stockWarehouse/setIsDeliveryNumbers', false)
  },
  methods: {
    repeateAgain() {
      this.formPayload.delivery_numbers.push({
        delivery_number_note: '',
      })
    },
    removeItem(index) {
      this.formPayload.delivery_numbers.splice(index, 1)
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map(item => [item[key], item])).values()]
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.searchSupplier(loading, search, this)
      } else {
        loading(true)
        setTimeout(() => {
          this.options = []
          this.getSupplier()
          loading(false)
        }, 500)
      }
    },
    searchSupplier: _.debounce((loading, search, vm) => {
      vm.$store.dispatch('supplier/getData', {
        uuid: '',
        params: {
          search,
          per_page: 10,
        },
      })
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.options = []
          response.data.data.data.forEach(item => {
            vm.options.push({
              title: item.name,
              label: item.uuid,
            })
          })
          loading(false)
        })
    }, 300),
    getSupplierDetail(id) {
      this.$store.dispatch('supplier/getDetailSupplier', {
        uuid: id,
        params: '',
      })
        .then(result => {
          localStorage.setItem('terms_of_payment_day', result.data.data.terms_of_payment_day || 0)
          setTimeout(() => {
            this.options.unshift({
              label: result.data.data.uuid,
              title: result.data.data.name,
            })
            const option = this.getUniqueListBy(this.options, 'label')
            this.options = option
          }, 500);
        }).catch(err => {
          // eslint-disable-next-line no-console
          console.log(err)
        })
    },
    getSupplier() {
      this.$store.dispatch('supplier/getData', {
        params: {
          per_page: 10,
        },
      }).then(result => {
        this.suppliers = result.data.data.data
        for (let index = 0; index < result.data.data.data.length; index++) {
          const element = result.data.data.data[index]
          this.options.push({
            title: element.name,
            label: element.uuid,
          })
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    getWarehouses() {
      this.$store.dispatch('warehouse/getData', {
        params: {
          per_page: 50,
        },
      }).then(result => {
        for (let index = 0; index < result.data.data.data.length; index++) {
          const element = result.data.data.data[index]
          this.optionsWarehouse.push({
            title: element.name,
            label: element.uuid,
          })
        }
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
